// Packages
import React from "react"

// Components
import LayoutContent from "@components/global/layout-content"

// Hooks
import useClientSideOnly from "@hooks/use-client-side-only"

// Utils
import { bankersWithWebsites } from "@utils/mb"

export let bankerData
function LayoutWalledMB(props) {
  bankerData = bankersWithWebsites.find(mb => {
    return (
      props.params.mb === mb.slug ||
      props.pageContext.slug === mb.slug ||
      props.path?.split("/")[1] === mb.slug
    )
  })

  const isMounted = useClientSideOnly()

  const newProps = {
    ...props,
    bankerData,
    isMounted,
  }

  return (
    <>
      <LayoutContent {...newProps} />
    </>
  )
}

export default LayoutWalledMB
