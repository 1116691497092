// Packages
import React, { useEffect } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"
import {
  colors,
  Button,
  GenericIcon,
  getMediaQueries,
  Logo,
  MainNav,
  NavLogo,
  NewsroomNav,
  SearchNav,
  SuperNav,
  useWindowSize,
} from "@abmg-components"

// Components
import NavHeadshot from "./nav-headshot-custom"
import ClientSideOnly from "@components/global/client-side-only"
import NavDropdown from "@components/global/nav-dropdown"

// Images
import Home from "@images/knowledge-center/home.svg"

// Utils
import { CategoryBadgeHoverColor } from "@utils/knowledge-center"
import { getWalledLink } from "@utils/walled"

// Styles
const { above, below, canHover } = getMediaQueries()
const StyledListItem = styled.li`
  a {
    align-items: center;
    display: flex;
    gap: 10px;
    ${canHover`
      :hover {
        svg {
          * {
            stroke: var(--pacific-blue-hex);
          }
        }
      }
  `}
  }
  button {
    border: none;
  }
  svg {
    * {
      stroke: var(--orca-grey-8-hex);
      transition: all 0.2s ease-in-out;
    }
  }
`

const NavigationStyles = createGlobalStyle`
.dropdown-subnav-items {
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 10px;
}
@media (min-width: 951px) {
  .dropdown-subnav-items {
    grid-column-gap: 10px;
    grid-template-columns: repeat(3, minmax(206px, 250px));
    justify-content: end;
  }
}
.mobile-sub-nav {
  position: relative;
}
.main-nav {
  //  opacity: ${props => (props.isMounted ? 1 : 0)};
  box-sizing: border-box;
  div:not(.fullABMGLogo):not(.logo-headshot) > svg, ${StyledListItem} svg {
    height: 16px;
    width: 16px;
  }
  li {
    padding-left: 0 !important;
    &::before {
      all: unset !important;
    }
  }
  &.pinned.hidden .nav-dropdown {
    display: none;
  }
  .apply > *, .pre-qual > * {
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    border-radius: 1px;
    font-weight: 600;
    text-align: center;
  }
  .apply > * {
    background-color: var(--pacific-blue);
    color: white;
  }
  .pre-qual > * {
    background-color: #ffd22e;
    color: black;
  }
  .mobile-nav-items-list, .mobile-sub-nav, .main-nav .burger-container  {
    background: #fff;
  }
  .mobile-nav-container {
    background: #fff;
    transition: background 0.1s linear;
    &:has(.dropdown-closed) {
      background: #fff;
    }
    &:has(.dropdown-open) {
      background: var(--ice-grey-1-hex);
    }
  }
  .mobile-nav  {
    background: transparent;
    .apply > *, .pre-qual > * {
      // padding: 12px 40px;
      // width: 327px;
    }
    .mobile-sub-nav {
      li {
        margin-bottom: 0;
      }
      a {
        padding: 12px;
      }
    }
  }

  div, .mobile-nav, .mobile-nav-container, .mobile-nav-items-list, button[alt*="navigation"] {
    background: ${props =>
      props.navVariant === "dark" && "var(--baltic-blue-hex) !important"};
   
  }
  *:not(.apply):not(.apply *):not(.pre-qual):not(.pre-qual *) {
    .burger-time span { /* Hamburger Menu button color */
     background: ${props => props.navVariant === "dark" && "#fff !important"};
    }  
    color: ${props => props.navVariant === "dark" && "#fff"};
    svg:not(.fullABMGLogo svg) {
      path {
        stroke: ${props => props.navVariant === "dark" && "#fff"};
      }
    }
      ${canHover`
          &[class*="super-nav"] a:hover {
            color: var(--pacific-blue-hex);
            svg:not(.fullABMGLogo svg) {
            path {
              stroke: ${props =>
                props.navVariant === "dark" && "var(--pacific-blue-hex)"};
            }
      }
          }
         
        `}
  }
  
}
.nav-items-list {
  .apply > * {
    padding: 12px;
    ${above.tablet`
      padding: 12px clamp(12px, 40px, 40px);
    `}
  }
  .pre-qual > * {
    padding: 12px 16px;
    ${above.tablet`
      padding: 12px clamp(12px, 40px, 40px);
    `}
  }
  ${StyledListItem}.apply, ${StyledListItem}.pre-qual {
    width: auto;
    &:before {
      all: unset;
      display: none;
    }
    ${below.tablet`
      display: inline-block;
    `};
    
  }
}
[class*=search-nav-items] {
  a {
    gap: 0px;
  }
}
`

// Main Component
function Navigation(props) {
  const {
    setNavOpen,
    navIsOpen,
    navDropdownOpen,
    pageName,
    bankerData,
    fullABMGLogo,
    searchBoxFocus,
    version,
    isMounted,
    type,
    category,
  } = props

  const windowWidth = useWindowSize().width

  const walledLogoOption2 =
    windowWidth < 601 ? (
      <Logo logo="abmg-nav-icon-default" />
    ) : (
      <Logo logo="abmg-nav-logo-with-icon-default" />
    )

  const reloadScrollBars = () => {
    document.documentElement.style.overflow = "auto" // firefox, chrome
    document.body.scroll = "yes" // ie only
  }

  const unloadScrollBars = () => {
    document.documentElement.style.overflow = "hidden" // firefox, chrome
    document.body.scroll = "no" // ie only
  }

  useEffect(() => {
    if (windowWidth > 950) {
      setNavOpen(false)
    }
  }, [windowWidth, setNavOpen])

  useEffect(() => {
    if (navIsOpen) {
      unloadScrollBars()
    } else {
      reloadScrollBars()
    }
  }, [navIsOpen])

  let headshot = bankerData?.headshot

  const isCurrentMainNavLink = props => {
    const { href, location } = props

    return location.pathname.match("knowledge-center")
      ? {
          className: `active`,
        }
      : {}
  }
  const isCurrentKCNavLink = ({ href, location }) => {
    let linkClass
    const basePath = location.pathname
      .split("/")
      .filter(string => string !== "")
    const pathEnd = basePath[basePath.length - 1]
    if (href.match("financial")) linkClass = "financial"
    else if (href.match("house")) linkClass = "home"
    else if (href.match("mortgage")) linkClass = "mortgage"
    else if (pathEnd === "knowledge-center") linkClass = "KC"
    return href === location.pathname + location.search
      ? { className: `active ${linkClass}` }
      : {}
  }

  let walledNavItems = [
    <StyledListItem key="about">
      <Link
        key="about"
        to={
          bankerData && !props.version
            ? `/${bankerData?.slug}/`
            : getWalledLink(
                `/${bankerData?.slug}/`,
                `/about/`,
                version,
                isMounted,
              ).link
        }
        activeClassName="active"
      >
        About
      </Link>
    </StyledListItem>,
    <StyledListItem
      key={
        version === "mb" || bankerData ? "reviews" : "search-mortgage-bankers"
      }
    >
      <Link
        key={
          version === "mb" || bankerData ? "reviews" : "search-mortgage-bankers"
        }
        to={
          bankerData && !props.version
            ? `/${bankerData?.slug}/reviews/`
            : getWalledLink(
                `/${bankerData?.slug}/reviews/`,
                `/search-mortgage-bankers/`,
                version,
                isMounted,
              ).link
        }
        activeClassName="active"
      >
        {version === "mb" || bankerData ? `Reviews` : `Find an Expert`}
      </Link>
    </StyledListItem>,
    <StyledListItem key="knowledge-center">
      <Link
        to={
          bankerData && !props.version
            ? `/${bankerData?.slug}/knowledge-center/`
            : getWalledLink(
                `/${bankerData?.slug}/knowledge-center/`,
                `/knowledge-center/`,
                version,
                isMounted,
              ).link
        }
        activeClassName="active"
        partiallyActive={true}
        getProps={isCurrentMainNavLink}
      >
        Knowledge Center
      </Link>
    </StyledListItem>,
    version === "mb" || bankerData ? (
      <StyledListItem key="contact">
        <Link
          key="contact"
          to={
            isMounted && bankerData
              ? `/${bankerData?.slug}/contact/`
              : `/contact/`
          }
          activeClassName="active"
        >
          Contact
        </Link>
      </StyledListItem>
    ) : null,
    version === "mb" || bankerData ? (
      // MB page
      !version ? (
        !bankerData?.team || bankerData?.apply ? (
          <StyledListItem className="apply" key="apply">
            <a
              className="button"
              href={bankerData?.apply}
              target="_blank"
              rel="noreferrer"
            >
              Apply Now
            </a>
          </StyledListItem>
        ) : (
          <StyledListItem key="apply" className="apply">
            <button
              className="button"
              onClick={() => props.setTeamApplyToggle(true)}
            >
              Apply Now
            </button>
          </StyledListItem>
        )
      ) : (
        // Walled MB page
        <ClientSideOnly
          key="apply"
          placeholder={
            <StyledListItem className="apply" key="apply">
              <a className="button" href={"#"} target="_blank" rel="noreferrer">
                Apply Now
              </a>
            </StyledListItem>
          }
        >
          {!bankerData?.team || bankerData?.apply ? (
            <StyledListItem className="apply" key="apply">
              <a
                className="button"
                href={bankerData?.apply}
                target="_blank"
                rel="noreferrer"
              >
                Apply Now
              </a>
            </StyledListItem>
          ) : (
            <StyledListItem key="apply" className="apply">
              <button
                className="button"
                onClick={() => props.setTeamApplyToggle(true)}
              >
                Apply Now
              </button>
            </StyledListItem>
          )}
        </ClientSideOnly>
      )
    ) : (
      <StyledListItem key="pre-qual" className="pre-qual">
        <Link
          key="pre-qual"
          className="button"
          to={
            props.location.pathname === "/smartpath/"
              ? "/fth-welcome/"
              : "/pre-qualify/"
          }
        >
          Pre-qualify
        </Link>
      </StyledListItem>
    ),
  ]
  const mbNavDropDown = (
    <NavDropdown
      key="nav-dropdown"
      mainItem={{
        text: "Client Tools",
        icon: "users",
        activeColor: colors["pacific-blue"].hex,
        defaultColor: colors["orca-grey-8"].hex,
      }}
      isLink={false}
      dropdownOpen={navDropdownOpen}
      subNavItems={[
        {
          text: "Mortgage Calculator",
          subtext: "Estimate your monthly mortgage cost",
          icon: "calculator-2",
          linkAttributes: {
            to: `/${bankerData?.slug}/#calculator`,
          },
          activeColor: colors["pacific-blue"].hex,
          defaultColor: colors["pacific-blue-4"].hex,
        },
        {
          text: "Pay My Bill",
          subtext: (
            <>
              <div>
                Loan payments made simple through our very{" "}
                <em>own Atlantic Bay Mortgage Group portal</em>.
              </div>
              <div>
                You can also access this for your{" "}
                <strong>1st-month payment</strong>.
              </div>
            </>
          ),
          icon: "dollar-sign",
          activeColor: colors["pacific-blue"].hex,
          defaultColor: colors["pacific-blue-4"].hex,
          linkAttributes: {
            href: `/payments/`,
            target: "_blank",
            rel: "noreferrer",
          },
        },

        {
          text: "Annual Mortgage Review",
          subtext: (
            <>
              <div>We'd like to be a part of your future.</div>
              <div>
                Discuss your real estate goals and needs with us by filling out
                this form.
              </div>
            </>
          ),
          icon: "edit-3",
          activeColor: colors["pacific-blue"].hex,
          defaultColor: colors["pacific-blue-4"].hex,
          linkAttributes: {
            to: `/${bankerData?.slug}/annual-mortgage-review`,
          },
        },
      ]}
    />
  )
  let walledSuperNavItems = [
    type === "mb" && bankerData && bankerData?.calendly ? (
      <StyledListItem key="calendly" className="calendly">
        <a href={bankerData.calendly} rel="noopener noreferrer" target="_blank">
          <GenericIcon icon="calendar" />
          Schedule Meeting
        </a>
      </StyledListItem>
    ) : version === "mb" ? (
      <ClientSideOnly key="calendly">
        {bankerData?.calendly && (
          <StyledListItem key="calendly" className="calendly">
            <a
              href={bankerData.calendly}
              rel="noopener noreferrer"
              target="_blank"
            >
              <GenericIcon icon="calendar" />
              Schedule Meeting
            </a>
          </StyledListItem>
        )}
      </ClientSideOnly>
    ) : null,
    props.version === "mb" || bankerData ? (
      mbNavDropDown
    ) : (
      <StyledListItem key="contact">
        <Link key="contact" to={`/contact/`} activeClassName="active">
          <GenericIcon icon="phone" />
          Contact
        </Link>
      </StyledListItem>
    ),
    props.type === "corporate" || version === "corporate" ? (
      <StyledListItem key="make-a-loan-payment">
        <a href="/payments/" rel="noopener noreferrer" target="_blank">
          <GenericIcon icon="dollar-sign" />
          Make a Loan Payment
        </a>
      </StyledListItem>
    ) : null,
  ]

  const walledMobileSubNavSections = [
    {
      items: walledSuperNavItems,
    },
  ]

  const navLogo =
    props.navVariant === "dark" ? (
      <NavLogo logo={<Logo logo="abmg-footer-logo-with-icon-white" />} />
    ) : (
      <NavLogo />
    )

  const walledLogo =
    (pageName?.match("404") && !bankerData) ||
    (!pageName?.match("404") && fullABMGLogo) ? (
      <Link to={bankerData ? `/${bankerData?.slug}/` : `/`}>{navLogo}</Link>
    ) : (
      <Link
        to={
          bankerData && !props.version
            ? `/${bankerData?.slug}/`
            : getWalledLink(`/${bankerData?.slug}/`, `/`, version, isMounted)
                .link
        }
      >
        {version === "mb" || pageName?.match("404") ? (
          // Use Client Side Only for both Walled MB and MB pages to prevent image flicker when navigating between the 2 types of pages
          <ClientSideOnly
            key="nav-headshot"
            placeholder={
              <NavHeadshot
              // professional={{
              //   ...bankerData,
              //   headshot: null,
              // }}
              />
            }
          >
            <NavHeadshot
              professional={{
                ...bankerData,
                headshot,
              }}
            />
          </ClientSideOnly>
        ) : (
          <ClientSideOnly
            key="nav-headshot"
            placeholder={
              <NavHeadshot
                professional={{
                  ...bankerData,
                  headshot,
                }}
              />
            }
          >
            <NavHeadshot
              professional={{
                ...bankerData,
                headshot,
              }}
            />
          </ClientSideOnly>
        )}
      </Link>
    )

  const walledLogo2 = fullABMGLogo ? (
    <Link
      to={getWalledLink(`/${bankerData?.slug}/`, `/`, version, isMounted).link}
    >
      {navLogo}
    </Link>
  ) : (
    <Link
      to={
        bankerData && !props.version
          ? `/${bankerData?.slug}/`
          : getWalledLink(`/${bankerData?.slug}/`, `/`, version, isMounted).link
      }
    >
      {version === "mb" ? (
        <ClientSideOnly
          key="nav-headshot"
          placeholder={
            <NavHeadshot
              // professional={{
              //   ...bankerData,
              //   headshot,
              // }}
              logo={walledLogoOption2}
            />
          }
        >
          <NavHeadshot
            professional={{
              ...bankerData,
              headshot,
            }}
            logo={walledLogoOption2}
          />
        </ClientSideOnly>
      ) : (
        <NavHeadshot
          professional={{
            ...bankerData,
            headshot,
          }}
          logo={walledLogoOption2}
        />
      )}
    </Link>
  )

  let navItems = []
  let superNavItems = []
  let mobileNavItems = []
  let mobileSubNavSections = []
  let superNav = null
  let subNav = null
  let attachSubNav = true
  let useHamburgerMenu = true
  let logo = null
  let navAlignHorizontal = "space-between"
  let addBoxShadow = props.navVariant === "dark" ? false : true
  let navFixed = true
  let pauseScrollingAnimation = false

  if (
    /*************************** Walled/Corporate/MB ***************************/
    type === "mb" ||
    type === "walled" ||
    type === "corporate"
  ) {
    navItems = walledNavItems
    mobileNavItems = walledNavItems
    superNavItems = walledSuperNavItems
    superNav = <SuperNav navItems={superNavItems} />
    mobileSubNavSections = walledMobileSubNavSections
    logo = walledLogo
  }

  /*********** -LEP (Walled category)  ***********/
  if (category === "lep") {
    navItems = [
      <Button
        buttonClass="toggle-button"
        key="toggle-language"
        buttonText={props.language === "spanish" ? "English" : "En Español"}
        type="primary"
        linkAttributes={{
          to: getWalledLink(
            `/${props.bankerData?.slug}/la-bahia/${
              props.language === "spanish" ? "" : "?lang=esp"
            }`,
            `/la-bahia/${props.language === "spanish" ? "" : "?lang=esp"}`,
            props.version,
            props.isMounted,
          ).link,
        }}
        // functions={{
        //   onClick: () =>
        //     props.setLanguage(
        //       props.language === "spanish" ? "english" : "spanish"
        //     ),
        // }}
      />,
    ]

    logo = walledLogo2
    superNav = null
    subNav = null
    attachSubNav = false
    mobileNavItems = null
    mobileSubNavSections = null
    useHamburgerMenu = false
    navFixed = false
  }
  /*********** -Works/Advantage (Walled page)  ***********/
  if (
    pageName === "works" ||
    pageName === "advantage" ||
    pageName === "black-history-month" ||
    pageName === "womens-history-month"
  ) {
    navItems = null
    logo = walledLogo2
    superNav = null
    subNav = null
    attachSubNav = false
    mobileNavItems = null
    mobileSubNavSections = null
    useHamburgerMenu = false
    navAlignHorizontal = "center"
    navFixed = false
  }

  /*********** -KC (Walled category)  ***********/
  if (category === "kc") {
    const baseSlug = getWalledLink(
      `${bankerData?.slug}/knowledge-center`,
      `knowledge-center`,
      version,
      isMounted,
    ).link

    const kcSubNavItems = [
      <StyledListItem key="knowledge-center">
        <Link
          className="KC"
          to={`/${baseSlug}/`}
          getProps={isCurrentKCNavLink}
          activeClassName="active"
          partiallyActive={true}
        >
          <img src={Home} alt="home icon" />
          <span>KC</span>
        </Link>
      </StyledListItem>,
      <StyledListItem key="mortgage-matters">
        <Link
          className="mortgage"
          to={
            bankerData
              ? `/${baseSlug}/mortgage-matters/`
              : `/${baseSlug}/mortgage-matters/`
          }
          activeClassName="active"
          partiallyActive={true}
          getProps={isCurrentKCNavLink}
        >
          Mortgage Matters
        </Link>
      </StyledListItem>,
      <StyledListItem key="financial-wellness">
        <Link
          className="financial"
          to={
            bankerData
              ? `/${baseSlug}/financial-wellness/`
              : `/${baseSlug}/financial-wellness/`
          }
          activeClassName="active"
          partiallyActive={true}
          getProps={isCurrentKCNavLink}
        >
          Financial Wellness
        </Link>
      </StyledListItem>,
      <StyledListItem key="house-to-home">
        <Link
          className="home"
          to={
            bankerData
              ? `/${baseSlug}/house-to-home/`
              : `/${baseSlug}/house-to-home/`
          }
          activeClassName="active"
          partiallyActive={true}
          getProps={isCurrentKCNavLink}
        >
          House to Home
        </Link>
      </StyledListItem>,
    ]
    const kcMobileSubNavItems = [
      <StyledListItem key="knowledge-center">
        <Link
          className="KC"
          to={`/${baseSlug}/`}
          getProps={isCurrentKCNavLink}
          activeClassName="active"
          partiallyActive={true}
        >
          <img src={Home} alt="home icon" />
          <span>KC</span>
        </Link>
      </StyledListItem>,
      <StyledListItem key="mortgage-matters">
        <Link
          className="mortgage"
          to={
            bankerData
              ? `/${baseSlug}/mortgage-matters/`
              : `/${baseSlug}/mortgage-matters/`
          }
          activeClassName="active"
          partiallyActive={true}
          getProps={isCurrentKCNavLink}
        >
          Mortgage
        </Link>
      </StyledListItem>,
      <StyledListItem key="financial-wellness">
        <Link
          className="financial"
          to={
            bankerData
              ? `/${baseSlug}/financial-wellness/`
              : `/${baseSlug}/financial-wellness/`
          }
          activeClassName="active"
          partiallyActive={true}
          getProps={isCurrentKCNavLink}
        >
          Financial
        </Link>
      </StyledListItem>,
      <StyledListItem key="house-to-home">
        <Link
          className="home"
          to={
            bankerData
              ? `/${baseSlug}/house-to-home/`
              : `/${baseSlug}/house-to-home/`
          }
          activeClassName="active"
          partiallyActive={true}
          getProps={isCurrentKCNavLink}
        >
          At Home
        </Link>
      </StyledListItem>,
    ]
    const suggestedSearchButtons = [
      "First-time home buyer",
      "Affordable",
      "Military",
      "Credit",
      "Refinance",
    ]

    pauseScrollingAnimation = !isMounted ? false : searchBoxFocus
    subNav = (
      <SearchNav
        navItems={kcSubNavItems}
        mobileNavItems={kcMobileSubNavItems}
        articleSlug={props.data?.contentfulBlogPost?.slug}
        suggestedSearchButtons={suggestedSearchButtons}
        categoryBadgeHoverColor={CategoryBadgeHoverColor(props.articleCategory)}
        articleCategory={props.articleCategory}
        articleProgressBarRoot={props.articleProgressBarRoot}
        articleProgressBarContainer={props.articleProgressBarContainer}
        navIsOpen={navIsOpen}
        searchBoxFocus={props.searchBoxFocus}
        setSearchBoxFocus={props.setSearchBoxFocus}
      />
    )
    attachSubNav = props.data?.contentfulBlogPost ? false : true
  }

  /*********** -Newsroom (Corporate category) ***********/
  if (category === "newsroom") {
    navItems = walledNavItems
    const newsroomSubNavItems = [
      <StyledListItem key="newsroom">
        <Link
          to="/newsroom/"
          className={props.location.pathname === "/newsroom/" ? "active" : ""}
        >
          Newsroom
        </Link>
      </StyledListItem>,
      <StyledListItem key="/newsroom/stories/">
        <Link to="/newsroom/stories/" className={props.series ? "active" : ""}>
          Stories
        </Link>
      </StyledListItem>,
      <StyledListItem key="/newsroom/facts/">
        <Link
          to="/newsroom/facts/"
          className={
            props.location.pathname === "/newsroom/facts/" ? "active" : ""
          }
        >
          Facts<span> and Figures</span>
        </Link>
      </StyledListItem>,
      <StyledListItem key="/newsroom/awards/">
        <Link
          to="/newsroom/awards/"
          className={
            props.location.pathname === "/newsroom/awards/" ? "active" : ""
          }
        >
          Awards
        </Link>
      </StyledListItem>,
      <StyledListItem key="/newsroom/press/">
        <Link
          to="/newsroom/press/"
          className={
            props.location.pathname === "/newsroom/press/" ? "active" : ""
          }
        >
          Press
        </Link>
      </StyledListItem>,
    ]
    subNav = <NewsroomNav navItems={newsroomSubNavItems} />
  }

  /*************************** ASA ***************************/
  if (type === "asa" || pageName === "keypleaz-atlanta") {
    navItems = null
    logo = navLogo
    navAlignHorizontal = "center"
    superNav = null
    subNav = null
    attachSubNav = false
    mobileNavItems = null
    mobileSubNavSections = null
    useHamburgerMenu = false
    navFixed = false
  }
  /*************************** Old ASA ***************************/
  if (type === "oldAsa") {
    navItems = walledNavItems.filter(item => {
      return item ? (item.props.to !== "/pre-qualify/" ? true : false) : false
    })

    mobileNavItems = navItems
    superNavItems = walledSuperNavItems
    superNav = <SuperNav navItems={superNavItems} />
    mobileSubNavSections = walledMobileSubNavSections
    logo = walledLogo
  }

  /*************************** Alumni Sponsor ***************************/
  if (type === "alumSponsor") {
    navItems = null
    logo = navLogo
    navAlignHorizontal = "center"
    addBoxShadow = false
    superNav = null
    subNav = null
    attachSubNav = false
    mobileNavItems = null
    mobileSubNavSections = null
    useHamburgerMenu = false
    navFixed = false
  }

  const navProps = {
    ...props,
    superNav,
    navItems,
    subNav,
    attachSubNav,
    mobileNavItems,
    mobileSubNavSections,
    logo,
    useHamburgerMenu,
    navAlignHorizontal,
    addBoxShadow,
    navFixed,
    pauseScrollingAnimation,
  }
  return (
    <>
      <NavigationStyles navVariant={props.navVariant} />
      <MainNav {...navProps} />
    </>
  )
}

export default Navigation
