import React from "react"

const Disclosures = props => {
  switch (props.disclosure) {
    default:
      return (
        <p>
          Loan programs may change at any time with or without notice.
          Information deemed reliable but not guaranteed. All loans subject to
          income verification, credit approval and property appraisal. Not a
          commitment to lend. Atlantic Bay Mortgage Group, L.L.C. NMLS #72043 (
          <a
            href="http://www.nmlsconsumeraccess.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            nmlsconsumeraccess.org
          </a>
          ) is an Equal Housing Lender.
        </p>
      )
    case "annual-mortgage-review":
      return (
        <p>
          Information deemed reliable but not guaranteed. All loans subject to
          income verification, credit approval and property appraisal. Not a
          commitment to lend. Atlantic Bay Mortgage Group, L.L.C. NMLS #72043 (
          <a
            href="http://www.nmlsconsumeraccess.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            nmlsconsumeraccess.org
          </a>
          ) is an Equal Opportunity Lender. Located at 600 Lynnhaven Parkway
          Suite 100 Virginia Beach, VA 23452.
        </p>
      )
    case "blog":
      return (
        <p>
          The views and statements expressed are deemed reliable as of the
          publish date indicated and may not be accurate or reliable at any
          future date. The views and statements provided are those of the
          author. Discussions regarding any financial information provided are
          not intended as individual recommendations and do not reflect the
          views or advice of Atlantic Bay Mortgage Group, L.L.C. The views
          expressed are subject to change at any time in response to changing
          circumstances in the market. *For refinances, the total finance
          charges may be higher over the life of the loan. Atlantic Bay Mortgage
          Group, L.L.C. disclaims any obligation to publicly update or revise
          any views expressed or information given. Loan programs may change at
          any time with or without notice. Information deemed reliable but not
          guaranteed. All loans subject to income verification, credit approval
          and property appraisal. Not a commitment to lend. Atlantic Bay
          Mortgage Group, L.L.C. NMLS #72043 (
          <a
            href="http://www.nmlsconsumeraccess.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            nmlsconsumeraccess.org
          </a>
          ) is an Equal Housing Lender. Located at 600 Lynnhaven Parkway Suite
          100 Virginia Beach, VA 23452.
        </p>
      )
    case "keypleaz":
      return (
        <p>
          Loan programs may change at any time with or without notice.
          Information deemed reliable but not guaranteed. All loans subject to
          income verification, credit approval and property appraisal. Not a
          commitment to lend. Atlantic Bay Mortgage Group, L.L.C. NMLS #72043 (
          <a
            href="http://www.nmlsconsumeraccess.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            nmlsconsumeraccess.org
          </a>
          ) is an Equal Opportunity Lender. Located at 600 Lynnhaven Parkway
          Suite 100 Virginia Beach, VA 23452.
        </p>
      )
    case "mbheader":
      return (
        <p>
          Loan programs may change at any time with or without notice.
          Information deemed reliable but not guaranteed. All loans subject to
          income verification, credit approval and property appraisal. Not a
          commitment to lend. Atlantic Bay Mortgage Group, L.L.C. NMLS #72043 (
          <a
            href="http://www.nmlsconsumeraccess.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            nmlsconsumeraccess.org
          </a>
          ) is an Equal Housing Lender. Located at 600 Lynnhaven Parkway Suite
          100 Virginia Beach, VA 23452.
        </p>
      )
    case "mbheader-texas":
      return (
        <p>
          Texas Mortgage Banker Registration and Residential Mortgage Loan
          Servicer Registration #72043. Loan programs may change at any time
          with or without notice. Information deemed reliable but not
          guaranteed. All loans subject to income verification, credit approval
          and property appraisal. Not a commitment to lend. Atlantic Bay
          Mortgage Group, L.L.C. NMLS #72043 (
          <a
            href="http://www.nmlsconsumeraccess.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            nmlsconsumeraccess.org
          </a>
          ) is an Equal Housing Lender. Located at 600 Lynnhaven Parkway Suite
          100 Virginia Beach, VA 23452.
        </p>
      )
    case "careers":
      return (
        <p>
          This communication does not constitute a promise or guarantee of
          employment. Atlantic Bay Mortgage provides equal employment
          opportunities (EEO) to all employees and applicants for employment
          without regard to race, color, religion, sex, national origin, age,
          disability or genetics. In addition to federal law requirements,
          Atlantic Bay Mortgage complies with applicable state and local laws
          governing nondiscrimination in employment in every location in which
          the company has facilities. Atlantic Bay Mortgage Group, L.L.C. NMLS
          #72043 (
          <a
            href="http://www.nmlsconsumeraccess.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            nmlsconsumeraccess.org
          </a>
          ) is an Equal Housing Lender.
        </p>
      )
    case "black-history":
      return (
        <p>
          Information is for educational purposes only and should not be relied
          upon by you. Information deemed reliable but not guaranteed. All loans
          subject to income verification, credit approval and property
          appraisal. Not a commitment to lend. Atlantic Bay Mortgage Group,
          L.L.C. NMLS #72043 ({" "}
          <a
            href="http://www.nmlsconsumeraccess.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            nmlsconsumeraccess.org
          </a>
          ) is an Equal Opportunity Lender. #72043 ({" "}
          <a
            href="http://www.nmlsconsumeraccess.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            nmlsconsumeraccess.org
          </a>
          ) is an Equal Housing Lender. Located at 600 Lynnhaven Parkway Suite
          100 Virginia Beach, VA 23452.
        </p>
      )
    case "la-bahia":
      return (
        <p>
          Information is for educational purposes only and should not be relied
          upon by you. Information deemed reliable but not guaranteed, featured
          products and programs subject to change. All loans subject to income
          verification, credit approval and property appraisal. Not a commitment
          to lend. Atlantic Bay Mortgage Group, L.L.C. NMLS #72043
          (nmlsconsumeraccess.org) is an Equal Opportunity Lender. #72043 (
          <a
            href="http://www.nmlsconsumeraccess.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            nmlsconsumeraccess.org
          </a>
          ) is an Equal Housing Lender. Located at 600 Lynnhaven Parkway Suite
          100 Virginia Beach, VA 23452. Atlantic Bay Mortgage Group, L.L.C. NMLS
        </p>
      )
    case "la-bahia-spanish":
      return (
        <p>
          Esta información es únicamente para fines educativos y usted no debe
          basarse en ella para tomar decisiones. La información se considera
          fidedigna, pero no está garantizada. Todos los préstamos están sujetos
          a la verificación de ingresos, a la aprobación de crédito y a la
          tasación de la propiedad. No constituye un compromiso del otorgamiento
          de un préstamo. Atlantic Bay Mortgage Group, L.L.C., NMLS n.º 72043 (
          <a
            href="http://www.nmlsconsumeraccess.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            nmlsconsumeraccess.org
          </a>
          ) , es un prestamista que ofrece igualdad de oportunidades con sede en
          600 Lynnhaven Parkway Suite 203, Virginia Beach, VA 23452.
        </p>
      )
    case "smartpath":
      return (
        <p>
          Information is for educational purposes only and should not be relied
          upon by you. Information deemed reliable but not guaranteed, featured
          products and programs subject to change. All loans subject to income
          verification, credit approval and property appraisal. Not a commitment
          to lend. Atlantic Bay Mortgage Group, L.L.C. NMLS #72043
          (nmlsconsumeraccess.org) is an Equal Opportunity Lender. #72043 (
          <a
            href="http://www.nmlsconsumeraccess.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            nmlsconsumeraccess.org
          </a>
          ) is an Equal Housing Lender. Located at 600 Lynnhaven Parkway Suite
          100 Virginia Beach, VA 23452. Atlantic Bay Mortgage Group, L.L.C. NMLS
        </p>
      )
  }
}

export default Disclosures
