import React from "react"
import styled, { css } from "styled-components"

const size = {
  tablet: 950,
  mobile: 600,
}
const below = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${size[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})
const StyledComponentContainer = styled.div`
  background: #031d30;
  .rule-wrapper {
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 48px;
    ${below.mobile`
    padding: 0 24px;
    `}
  }
  hr {
    height: 1px;
    border-bottom: 1px solid #ffffff;
    margin: 0;
    padding: 0;
  }
`
const StyledFooterWrapper = styled.div`
  background: #031d30;
  padding: 64px 48px 80px;
  margin: 0 auto;
  max-width: 1120px;
  ${below.tablet`
  padding: 64px 48px;
  `}
  ${below.mobile`
  padding: 48px 24px 40px;
  `}

  p {
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    margin-bottom: 16px;
    :last-of-type {
      margin-bottom: 24px;
    }
  }
  a {
    float: right;
    font-size: 12px;
    color: #ffffff;
    ${below.mobile`
    float: left;
    `}
  }
`

const AlumSponsorFooter = props => {
  return (
    <StyledComponentContainer>
      <div className="rule-wrapper">
        <hr />
      </div>

      <StyledFooterWrapper>
        <p>
          By completing this form, you understand that any information you
          provide may be used by Atlantic Bay Mortgage Group, L.L.C. for
          commercial purposes. Loan programs may change at any time with or
          without notice. Information deemed reliable but not guaranteed. All
          loans subject to income verification, credit approval and property
          appraisal. Not a commitment to lend.
        </p>
        <p>
          Atlantic Bay Mortgage Group, L.L.C. NMLS #72043
          (nmlsconsumeraccess.org) is an Equal Opportunity Lender. Located at
          600 Lynnhaven Parkway Suite 100 Virginia Beach, VA 23452.
        </p>
      </StyledFooterWrapper>
    </StyledComponentContainer>
  )
}

export default AlumSponsorFooter
