// Packages
import { useEffect, useState } from "react"

// Main Component
function ClientSideOnly(props) {
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => setIsMounted(true), [])
  if (!isMounted && !props.placeholder) return null
  else if (!isMounted && props.placeholder) {
    return props.placeholder
  }
  return props.children
}

export default ClientSideOnly
