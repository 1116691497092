// Packages
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"
import { GenericIcon, getMediaQueries } from "@abmg-components"
import { AnimatePresence, motion } from "framer-motion"

const { canHover, above } = getMediaQueries()

// Styles
const MainItemContent = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  svg {
    margin-right: 10px;
  }
  svg:last-of-type {
    margin-left: 5px;
  }
`

const Link = styled(GatsbyLink)`
  padding: 12px;
  svg {
    height: 16px;
    width: 16px;
    * {
      stroke: ${props => props.defaultColor};
      transition: all 0.2s ease-in-out;
    }
  }
  ${canHover`
  :hover {
    * {
    color: ${props => props.activeColor};
    }
    svg {
      * {
        stroke: ${props => props.activeColor};
      }
    }
  }
`}
  * {
    color: ${props => props.defaultColor};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    transition: 0.3s all ease-in-out;
  }
  ${above.tablet`
    padding: 12px;
  `}
`
const SubNavLink = styled(Link)`
  &.subnav-link {
    align-items: center;
    column-gap: 10px;
    display: flex;
    padding: 12px;
    &:before {
      all: unset;
      display: none;
    }
    svg {
      height: 16px;
      width: 16px;
      * {
        stroke: ${props => props.defaultColor};
        transition: all 0.3s ease-in-out;
      }
    }
    .text {
      color: ${props => props.defaultColor};
      font-size: 16px;
      font-variant-numeric: lining-nums proportional-nums;
      line-height: 20px;
    }
    ${canHover`
      :hover {
        text-decoration: none;
        .text {
          color: ${props => props.activeColor};
        }
        svg {
          * {
            stroke: ${props => props.activeColor};
          }
        }
      }
    `}
  }
`

const SubNavItemsContainer = styled(motion.div)`
  background: var(--ice-grey-1-hex);
  border-top: 1px solid var(--grey-3-hex);
  left: 0;
  top: 100%;
  position: absolute;
  z-index: 100;
  width: 100%;
  ${above.tablet`
  padding-block: 30px;
  `}
`
const SubNavItems = styled.div`
  padding: 20px 24px 40px 24px;
  ${above.mobile`
    margin-inline: auto;
    max-width: 1248px;
    padding-inline: 48px;
  `}
`
const SubNavItem = styled.div``
const SubText = styled.div`
  padding: 10px 24px 10px 38px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  &,
  * {
    color: var(--bottlenose-grey-5-hex);
    font-size: 14px;
    font-variant-numeric: lining-nums proportional-nums;
    line-height: 22px;
  }
  strong {
    font-weight: 700;
  }
  .text {
    color: var(--pacific-blue-4-hex);
    font-size: 16px;
    font-variant-numeric: lining-nums proportional-nums;
    line-height: 20px;
  }
`

// Main Component
export default function NavDropdown({
  screen,
  mainItem,
  linkAttributes,
  subNavItems,
  dropdownOpen,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(dropdownOpen)
  useEffect(() => {
    // Close dropdown when page changes
    if (!dropdownOpen) setIsDropdownOpen(false)
  }, [dropdownOpen])
  const functions = {
    onClick: () => {
      setIsDropdownOpen(!isDropdownOpen)
    },
  }
  const mainItemContent = (
    <MainItemContent
      className={`main-item-content ${isDropdownOpen ? "dropdown-open" : ""}`}
      {...functions}
    >
      {mainItem.icon && <GenericIcon icon={mainItem.icon} />}
      {mainItem.text}{" "}
      {isDropdownOpen ? (
        <GenericIcon icon="chevron-up" />
      ) : (
        <GenericIcon icon="chevron-down" />
      )}
    </MainItemContent>
  )

  return (
    <>
      <Link
        {...linkAttributes}
        className="dropdown-nav-item"
        activeColor={mainItem.activeColor}
        defaultColor={mainItem.defaultColor}
        as={
          linkAttributes && linkAttributes.href
            ? "a"
            : linkAttributes
              ? GatsbyLink
              : "div"
        }
      >
        {mainItemContent}
      </Link>
      <AnimatePresence>
        {isDropdownOpen && (
          <SubNavItemsContainer
            className="nav-dropdown"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              duration: 0.1,
              ease: "linear",
            }}
          >
            <SubNavItems
              className={`${
                isDropdownOpen ? "dropdown-open" : "dropdown-closed"
              } dropdown-subnav-items`}
            >
              {subNavItems.map(item => {
                if (!item) return null
                const { icon, text, subtext } = item
                const subLinkAttributes = item.linkAttributes
                const content = (
                  <div className="content">
                    {icon && <GenericIcon icon={icon} />}
                    <div>
                      <p className="text">{text}</p>
                      {subtext && <div className="subtext">{subtext}</div>}
                    </div>
                  </div>
                )

                return (
                  <SubNavItem
                    className="dropdown-subnav-item"
                    key={`subnav-item-${text.replace(/\s/g, "-")}`}
                  >
                    <SubNavLink
                      {...subLinkAttributes}
                      activeClassName="active"
                      className="subnav-link"
                      activeColor={item.activeColor}
                      defaultColor={item.defaultColor}
                      as={
                        subLinkAttributes && subLinkAttributes.href
                          ? "a"
                          : subLinkAttributes
                            ? GatsbyLink
                            : "div"
                      }
                    >
                      {icon && <GenericIcon icon={icon} />}
                      <div className="text">{text}</div>
                    </SubNavLink>
                    {subtext && (
                      <SubText className="subtext">{subtext}</SubText>
                    )}
                  </SubNavItem>
                )
              })}
            </SubNavItems>
          </SubNavItemsContainer>
        )}
      </AnimatePresence>
    </>
  )
}

NavDropdown.propTypes = {
  /**
   * Is the nav item going to be shown on a mobile (tablet size included) screen or desktop screen? <br /> <br />
   * If you choose desktop, a parent of the nav item must have position relative in order for the nav item to properly show
   */
  screen: PropTypes.oneOf(["mobile", "desktop"]),
  /**
   * Text for the main item that has toggle capability to toggle sub nav items
   */
  mainItem: PropTypes.object.isRequired,
  /**
   * Array of objects
   * Each object should contain text, subtext (optional), icon (optional), and linkTo
   */
  subNavItems: PropTypes.array.isRequired,
  /**
   * Link Attributes e.g. { to: "/", target: "_blank"}
   */
  linkAttributes: PropTypes.shape(),
}
