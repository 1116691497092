// Packages
import { useEffect, useState } from "react"

// Hook
function useClientSideOnly() {
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])
  return isMounted
}

export default useClientSideOnly
