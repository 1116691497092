// Packages
import { scrollToHash } from "abmg-components"
// Styles
import "./src/styles/global.css"

import { findPath } from "./.cache/find-path"

// Utils
import { bankersWithWebsites } from "@utils/mb"

export { wrapPageElement } from "./gatsby-shared"

export const onRouteUpdate = ({ location }) => {
  scrollToHash(location)
}

// Load 404 page for Walled MB pages that don't belong to a MB (e.g. /ginny/abcares instead of /ginnyphillips/abcares)
export const onClientEntry = () => {
  const loader = window.___loader
  const { isPageNotFound, loadPage, loadPageSync } = loader

  const getLoadPath = path => {
    let loadPath = path
    if (!window.location.pathname.split("/")) return loadPath
    if (path.match("404")) return path

    // If it is a Walled MB page and the page doesn't belong to a MB
    if (findPath(window.location.pathname).match(":mb")) {
      const param = window.location.pathname.split("/")[1]
      const mbExists = bankersWithWebsites.find(({ slug }) => param === slug)
      if (!mbExists) {
        loadPath = !process.env.BUILD_STAGE.match("develop")
          ? `/404.html`
          : "/dev-404-page/"
      }
    }

    return loadPath
  }

  loader.loadPage = async path => {
    return await loadPage(getLoadPath(path))
  }
  loader.loadPageSync = path => {
    return loadPageSync(getLoadPath(path))
  }
  loader.isPageNotFound = path => {
    if (getLoadPath(path).match("404")) return true
    return isPageNotFound(path)
  }
}
