import React from "react"
import styled, { css } from "styled-components"

import Icon from "./icon"

const size = {
  mobile: 600,
}
const below = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${size[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: hsla(0, 0%, 0%, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ModalCard = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 0 17px;
  padding: 32px 75px;
  max-width: 575px;
  position: relative;
  box-shadow: 0px 4.8412px 29.0472px rgba(19, 101, 186, 0.15);
  ${below.mobile`
    padding: 32px 37px;
    `};

  .modal-header {
    color: black;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;
    ${below.mobile`
    font-size: 18px;
    line-height: 24px;
    `};
  }
`
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  max-height: 104px;
  ${below.mobile`
    max-height: 80px;
    margin-bottom: 32px;
    `};
`
const Caption = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: black;
  margin: 0 0 40px;
  padding: 0;
  text-align: left;
  ${below.mobile`
   font-size: 12px;
   line-height: 16px;
   margin: 0 0 32px;
    `};
`
const Button = styled.a`
  color: white;
  display: inline-block;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  padding: 20px 0;
  width: 100%;
  margin-bottom: 16px !important;
  border-radius: 2.5px;
  background-color: var(--pacific-blue);
  text-decoration: none;
  :visited {
    text-decoration: none;
    color: white;
  }
  :hover {
    text-decoration: none;
  }
  ${below.mobile`
    font-size: 18px;
    line-height: 24px;
    `};
`
const Exit = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 24px;
  color: #264765;
  margin: 0 auto !important;
  ${below.mobile`
    font-size: 16px;
    `};
`

const ABIModal = props => {
  return (
    <ModalWrapper
      onClick={event => {
        event.stopPropagation()
        props.toggleModal(false)
      }}
    >
      <ModalCard onClick={event => event.stopPropagation()}>
        <IconWrapper>
          <Icon
            icon="circle-icon-color"
            height="100%"
            style={{ margin: "0 auto" }}
          />
        </IconWrapper>
        <div className="modal-header">You are leaving AtlanticBay.com.</div>
        <Caption>
          By selecting “Continue” I acknowledge that I am leaving Atlantic Bay
          Mortgage Group L.L.C.’s website and navigating to AB Insurance L.L.C,
          an affiliated entity under common ownership, offering consumer
          insurance products. By leaving Atlantic Bay’s site I am navigating
          away from information on residential mortgage loan products.
        </Caption>
        <div
          css={`
            text-align: center;
          `}
        >
          <Button href="https://www.abinsures.com">Continue</Button>
        </div>
        <Exit
          onClick={() => {
            props.toggleModal(false)
          }}
        >
          No thanks, take me back.
        </Exit>
      </ModalCard>
    </ModalWrapper>
  )
}

export default ABIModal
