import { colors } from "@abmg-components"
import React from "react"
import styled, { css } from "styled-components"
import XIcon from "@images/knowledge-center/x.svg"
import useClickOutside from "../click-outside"

// Utils
import { CategoryColorDarkFinder } from "@utils/knowledge-center"

const size = {
  tablet: 950,
  mobile: 600,
}

const above = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${size[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})

const ToolTipWord = styled.span`
  position: relative;
  display: inline;
  color: var(--pacific-blue);
  border-bottom: 1px dotted var(--pacific-blue);
  &:hover {
    border-bottom: 1px solid var(--pacific-blue);
    cursor: pointer;
  }

  ${above.tablet`
  &:hover {
    cursor: unset;
  }

  &:hover > span {
    display: inline-block;
    width: 260px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  `}
`

const ToolTipContainer = styled.span`
  display: none;
  text-align: left;

  padding: 16px;
  background-color: var(--ice-grey);
  box-shadow: 0px 1px 16px 2px rgba(117, 128, 143, 0.1);
  border-radius: 2px;
  border: ${props => `0.5px solid ${props.categoryColor}`};

  font-size: 14px !important;
  line-height: 17.6px !important;
  color: ${props => `${props.categoryColor} !important`};
`

const ToolTipModalTextWrapper = styled.span`
  padding: 24px;
  position: fixed;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--ice-grey);
  z-index: 100;
  width: calc(100vw - 48px);
  max-width: 640px;

  ${above.mobile`
  width: calc(100vw - 96px);
  `}

  .modal-tooltip-word {
    display: block;
    color: ${props => props.categoryColor};
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
  }

  .modal-tooltip-text {
    font-size: 20px;
    line-height: 32px;
    color: #2d3748;
  }

  .modal-tooltip-word-close-icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .close-icon {
    margin-top: 0;
  }
`

const InvisibleButton = styled.button`
  width: 100%;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 24px;
  height: 24px;
`

const TooltipDesktop = styled.span`
  @media (max-width: 949px) {
    display: none;
  }
`

const TooltipMobileTablet = styled.span`
  ${above.tablet`
display:none;
`}
`

const ArticleToolTip = ({ category, fields, setTooltipOverlay }) => {
  const tooltipText = fields.tooltip.childMarkdownRemark.rawMarkdownBody
  const tooltipWord = fields.word

  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(
    false,
    setTooltipOverlay
  )

  return (
    <>
      <TooltipMobileTablet>
        <ToolTipWord
          className="article-tooltip article-component"
          onClick={() => {
            setIsComponentVisible(true)
            setTooltipOverlay(true)
          }}
        >
          {tooltipWord}
          <ToolTipContainer categoryColor={CategoryColorDarkFinder(category)}>
            {tooltipText}
          </ToolTipContainer>
        </ToolTipWord>
      </TooltipMobileTablet>

      {isComponentVisible && (
        <ToolTipModalTextWrapper
          ref={ref}
          categoryColor={CategoryColorDarkFinder(category)}
        >
          <span className="modal-tooltip-word-close-icon-container">
            <span className="modal-tooltip-word">{tooltipWord}</span>
            <InvisibleButton
              onClick={() => {
                setIsComponentVisible(false)
                setTooltipOverlay(false)
              }}
            >
              <img className="close-icon" src={XIcon} alt="close icon" />
            </InvisibleButton>
          </span>

          <span className="modal-tooltip-text">{tooltipText}</span>
        </ToolTipModalTextWrapper>
      )}

      <TooltipDesktop>
        <ToolTipWord className="article-tooltip article-component">
          {tooltipWord}
          <ToolTipContainer categoryColor={CategoryColorDarkFinder(category)}>
            {tooltipText}
          </ToolTipContainer>
        </ToolTipWord>
      </TooltipDesktop>
    </>
  )
}

export default ArticleToolTip
