import React, { useState, useEffect, useContext } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import { animateScroll as scroll } from "react-scroll"
import {
  Address,
  ButtonArrowIcon,
  colors,
  getMediaQueries,
  Logo,
  CSSVariables,
  FooterItem,
  getAddress,
} from "@abmg-components"

import ABIModal from "./abi-modal"
import ACTModal from "./act-modal"
import Icon from "./icon"
import Disclosure from "./disclosures"

const { above, below } = getMediaQueries()

const FooterWrapper = styled.div`
  background-color: #031d30;
`
const Container = styled.div`
  max-width: 1248px;
  margin: 0 auto;
  padding: 56px 48px;
  .affiliations-link {
    :hover {
      text-decoration: none;
    }
  }
  ${below.mobile`
   padding: 56px 24px;
    `};
  .contact {
    width: 100%;
    &.corporate * {
      ${above.tablet`
        text-align: right;
        justify-content: flex-end;
      `}
    }
    &:not(.corporate) * {
      ${above.mobile`
        text-align: right;
        justify-content: flex-end;
      `}
    }

    ${above.tablet`
      width: auto;
    `}
  }
  .flex-container {
    column-gap: 24px;
  }
  .footer-column {
    // margin-bottom: 8px !important;
    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 0;
      padding: 0;
      li {
        display: flex;
      }
      ${below.tablet``}
      &.social {
        ${below.tablet`margin-right: 0px;`}
      }
    }
  }
  .subtitle-sans-regular {
    font-size: 20px;
    line-height: 32px;
    margin: 0;
    margin-bottom: 8px;
    color: var(--grey-2-hex);

    ${above.mobile`
      font-size: 28px;
    `}
  }
`
const Divider = styled.hr`
  border-bottom: 1px solid white;
  margin-bottom: 40px;
  ${below.mobile`
    margin-bottom: 32px;
    `};
`
const FlexContainer = styled.div`
  display: flex;
  align-items: ${props => props.alignItems || "center"};
  margin: ${props => props.margin || 0};
  justify-content: ${props => props.justifyContent || "flex-start"};

  li {
    list-style-type: none;

    a {
      color: var(--ice-grey-1-hex);
      font-size: 14px;
      line-height: 16px;
      text-decoration: none;
      :visited {
        text-decoration: none;
        // color: white;
      }
      &.phone {
        margin-top: 8px !important;
      }
      ${above.mobile`
        font-size: 16px;
        line-height: 24px;
      `}//   ${below.tablet`
    // font-size: 12px;
    // line-height: 16px;
    // letter-spacing: -.75px;
    // `}
    }
    button {
      cursor: pointer;
      // font-size: 16px;
      // line-height: 24px;
      font-size: 14px;
      line-height: 16px;
      color: white;
      border: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
      text-decoration: none;
      ${above.mobile`
        font-size: 16px;
        line-height: 24px;
      `}
      ${below.tablet`
    // font-size: 12px;
    // line-height: 16px;
    // letter-spacing: -.75px;
    `};
    }
  }
  .contact {
    text-align: right;
    ${below.tablet`
    text-align: left;
    `}
  }

  p {
    margin: 0 !important;

    a {
      color: white;
      text-decoration: none;
      :visited {
        // color: white;
        text-decoration: none;
      }
    }
  }
`
const Tagline = styled.div`
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 0 0 40px 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button {
    background: transparent;
    border-radius: 50%;
  }
  .name-container {
    align-items: center;
    display: flex;
  }
  .tagline {
    font-size: 28px;
    line-height: 32px;
    font-weight: 400;
    color: var(--ice-grey-1-hex);
    justify-content: space-between;
    a {
      display: flex;
    }
  }
`

const Badge = styled.div`
  flex: auto;
  // min-width: 248px;
  // margin: 0 24px 0 0;
  align-self: flex-end;
  a {
    // margin-right: 16px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 0;
  }
  .footer-item:nth-of-type(1) {
    // color: var(--ice-grey-1-hex);
    // font-size: 12px;
    // letter-spacing: -0.56px;
    // line-height: 16px;
    // margin: 0 0 8px;
    width: 100%;
    p {
      font-size: 16px;
      line-height: 20px;
    }
    ${above.mobile`
      margin-left: 16px;
      order: 3;
      width: auto;
    `}
  }
  ${above.mobile`
    grid-column: 1 / 3;
  `}
`

const LegalContainer = styled(FlexContainer)`
  background: var(--baltic-blue-hex);
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-row-gap: 32px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 1152px;
  ul {
    list-style-type: none;
    li {
      display: inline-block;
      padding-left: 0;
      a {
        font-size: 16px;
        line-height: 20px;
        width: 150px;
      }
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
    li::before {
      height: 0;
      width: 0;
    }
    ${above.mobile`
      align-items: center;
      display: flex;
    `}
  }
  ${below.tablet`
    align-items: flex-start;
    flex-wrap: wrap;
    `};
  ${above.mobile`
    grid-column-gap: 26px;
  `}
`

const FooterBottomContainer = styled.div`
  border-top: 1px solid var(--bottlenose-grey-5-hex);
  padding-top: 24px;
  ${above.mobile`
    padding-top: 40px;
  `}
`

const DisclosureContainer = styled.div`
  flex-grow: 1;
  // margin-left: 24px;
  // max-width: 720px;
  grid-row: 1 / 3;
  grid-column: 1 / 3;
  p {
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.56px;
  }
  ${below.tablet`
    margin-left: 0px;
    // order: -1;
    min-width: 100%;
    // margin-bottom: 32px;
    `};
  ${above.mobile`
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  `}
`
const FooterLinks = styled(FlexContainer)`
  row-gap: 24px;
  flex-wrap: wrap;
  justify-content: space-between;
  opacity: 1;
  pointer-events: initial;
  transition: 0.2s ease-in-out;
  &:not(.corporate) {
    ${above.mobile`
      flex-wrap: nowrap;
    `}
  }
  &.loading {
    opacity: 0;
    pointer-events: none;
  }
  ul {
    li {
      padding-left: 0px;
      &:before {
        display: none;
      }
      button {
        text-align: left;
      }
    }
  }
`

const EOLWrapper = styled.div`
  min-height: 40px;
  max-width: 45px;
  // margin: 0 0 0 24px;
  justify-self: end;
  padding: 0;
  flex-grow: 0;
  align-self: center;
  display: flex;
  ${above.mobile`
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    justify-self: start;
  `}
  ${below.tablet`
    margin: 0;
    `};
`

const AffiliatiosSection = styled.div`
  align-items: center;
  background: var(--arctic-blue-4-hex);
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  padding: 40px;
  p {
    color: #fff;
  }
  ${above.mobile`
    margin: 56px 0;
  `}
  ${above.tablet`
      flex-direction: row;
      justify-content: space-between;
    `}
  .affiliations {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
    margin-top: 40px;
    ${above.tablet`
    flex-direction: row;
    justify-content: space-between;
      gap: 40px;
      justify-content: center;
      margin-top: 0;
    `}
    ${above.tablet`
     
    `}
  }
  .header {
    color: var(--grey-3-hex);
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    margin: 0;
  }
  .subheader {
    color: var(--grey-2-hex);
    font-size: 20px;
    line-height: 28px;
  }
`

const LEPFooter = props => {
  const [isABIModalActive, ABIToggleModal] = useState(false)
  const [isACTModalActive, ACTToggleModal] = useState(false)

  const [isIOS, setIsIOS] = useState(false)

  const content = {
    english: {
      links: [
        {
          to: `/legal/`,
          text: `Legal`,
          legalItem: true,
        },
        {
          to: `/privacy-policy/`,
          text: `Privacy Policy`,
          legalItem: true,
        },
      ],
    },
    spanish: {
      links: [
        {
          to: `/legal/`,
          text: `Legal`,
          legalItem: true,
        },
        {
          to: `/privacy-policy-es/`,
          text: `Política de privacidad`,
          legalItem: true,
        },
      ],
    },
  }

  useEffect(() => {
    const isIOS =
      (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
      !window.MSStream
    if (isIOS) {
      setIsIOS(true)
    }
  }, [])

  return (
    <FooterWrapper>
      <Container>
        <Tagline onClick={scroll.scrollToTop}>
          <div className="name-container">
            <Logo logo="abmg-footer-logo-with-icon-white" />
          </div>
        </Tagline>

        <FooterBottomContainer>
          <LegalContainer>
            <Badge>
              <ul>
                <FooterItem
                  itemText={`© ${new Date().getFullYear()} Atlantic Bay Mortgage Group`}
                  legalItem={true}
                />
                {content[props.language].links.map(props => {
                  return (
                    <FooterItem
                      linkAttributes={{
                        to: props.to,
                      }}
                      itemText={props.text}
                      legalItem={props.legalItem}
                    />
                  )
                })}
                {/* <FooterItem
                  linkAttributes={{
                    to: `/legal/`,
                  }}
                  itemText="Legal"
                  legalItem={true}
                />
                <FooterItem
                  linkAttributes={{
                    to:
                      props.language === "english"
                        ? `/privacy-policy/`
                        : `/privacy-policy-es/`,
                    rel: "noopener noreferrer",
                    target: "_blank",
                  }}
                  itemText={
                    props.language === "english"
                      ? "Privacy Policy"
                      : "Política de privacidad"
                  }
                  legalItem={true}
                /> */}
              </ul>
            </Badge>
            <EOLWrapper>
              <Icon icon="EOL" height="40px" width="32px" />
            </EOLWrapper>
            <DisclosureContainer>
              <Disclosure disclosure={props.disclosure} />
            </DisclosureContainer>
          </LegalContainer>
        </FooterBottomContainer>
      </Container>
    </FooterWrapper>
  )
}

export default LEPFooter
