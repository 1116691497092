// Packages
import React from "react"
import styled from "styled-components"

// Components
import LayoutContent from "@components/global/layout-content"

// Styles
const PageWrapper = styled.div`
  position: relative;
`

// Functions
const getClonedChildren = (children, props) =>
  React.cloneElement(children, {
    ...props,
  })

// Main Component
const Layout = props => {
  const newProps = {
    ...props,
  }

  return <LayoutContent {...newProps} />
}

export default Layout
