// Packages
import { getPhoneNumbers } from "@abmg-components"

// Data
const bankersDataJSON = require("@data/banker-data.json")

// Functions
export const getApplyLink = (applyLink, theme) => {
  return applyLink
    ? applyLink
    : theme.match("team")
    ? null
    : `https://apply.atlanticbay.com/`
}

export const sortAlphabetically = (data, property, order) => {
  return data.sort((a, b) => {
    if (order.match(/asc/i)) {
      if (a[property] > b[property]) return 1
      if (a[property] === b[property]) return 0
      return -1
    } else if (order.match(/desc/i)) {
      if (b[property] > a[property]) return 1
      if (b[property] === a[property]) return 0
      return -1
    }
  })
}

export const sortByDate = (data, property, order) => {
  return data.sort((a, b) => {
    if (order.match(/asc/i)) {
      return a[property] - b[property]
    } else if (order.match(/desc/i)) {
      return b[property] - a[property]
    }
  })
}

export const getAllBankerData = bankerData => {
  let allBankerData
  if (bankerData) {
    const teamMembers = bankerData.team || bankerData.members
    const team = teamMembers?.map(member => {
      return {
        ...member,
        email: member.contactInformation.email,
        apply: getApplyLink(member.applyLink, member.theme),
        street: member.streetAddress,
      }
    })

    const teamEmails = team?.map(member => member.email)
    const sharedProperties = {
      mobile: bankerData.contactInformation.mobile,
      office: bankerData.contactInformation.office,
      extension: bankerData.contactInformation.extension,
      favoredPhone: bankerData.contactInformation.favoredPhone,
      phone: getPhoneNumbers(bankerData.contactInformation)?.primaryPhone,
      phoneText: getPhoneNumbers(bankerData.contactInformation)
        ?.primaryPhoneText,
      altPhone: getPhoneNumbers(bankerData.contactInformation)?.altPhone,
      altPhoneText: getPhoneNumbers(bankerData.contactInformation)
        ?.altPhoneText,
      category: bankerData.category,
    }
    if (bankerData.category === "team") {
      allBankerData = {
        ...bankerData,
        ...sharedProperties,
        apply: bankerData.applyLink,
        teamEmails,
        team,
        members: team,
        name: bankerData.name,
        email: bankerData.contactInformation.teamEmail,
        formEmail: bankerData.contactInformation.teamEmail
          ? [bankerData.contactInformation.teamEmail]
          : teamEmails,
        calendly: bankerData.contactInformation.calendly,
        city: bankerData.address?.city,
        state: bankerData.address?.state,
        street: bankerData.address?.street,
        suite: bankerData.address?.suite,
        zip: bankerData.address?.zip,
        facebook: bankerData.socials?.facebook,
        linkedin: bankerData.socials?.linkedin,
        zillow: bankerData.socials?.zillow,
        instagram: bankerData.socials?.instagram,
      }
    } else if (bankerData.category === "individual") {
      let name = bankerData.fullName
      allBankerData = {
        ...bankerData,
        ...sharedProperties,
        apply: getApplyLink(bankerData.applyLink, bankerData.theme),
        name,
        street: bankerData.streetAddress,
        email:
          bankerData.contactInformation.teamEmail ||
          bankerData.contactInformation.email,
        /*  If bankerData is for an individual
              -If there is a team email, use that
              -If there is not a team email, use individual email
            If bankerData is for a team
              -If there is a team email, use that
              -If there is not a team email, use individual emails for each team member
        */
        formEmail: bankerData.contactInformation.teamEmail
          ? [bankerData.contactInformation.teamEmail]
          : team
          ? teamEmails
          : [bankerData.contactInformation.email],
        individualEmail: bankerData.contactInformation.email,
        teamEmails,
        team,
      }
    }
  }
  return allBankerData
}

// Variables
export const allBankersData = bankersDataJSON.map(bankerData => {
  return getAllBankerData(bankerData)
})
export const bankersWithWebsites = allBankersData.filter(mb => {
  return mb.distribution?.includes("Website") || mb.category === "team"
})

export const bankersInMBSearch = allBankersData.filter(mb => {
  return mb.distribution?.includes("Mortgage Banker Search")
})
