// Packages
import React from "react"
import styled, { css } from "styled-components"
import { Logo } from "@abmg-components"

const size = {
  desktop: 1152,
  tablet: 950,
  mobile: 600,
}
const below = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${size[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})
const Wrapper = styled.div`
  background: var(--arctic-blue-hex);
  min-height: 200px;
  margin: 0;
  padding: 64px 0;
  ${below.tablet``}
  ${below.mobile``}
`
const Container = styled.div`
  max-width: 1248px;
  margin: 0 auto;
  padding: 0 48px;
  * {
    color: #fff;
  }
  ${below.mobile`padding: 0 24px;`}

  p {
    font-size: 14px;
    line-height: 16px;
    color: #fff;
  }
  svg {
    display: block;
    margin: 0 auto 40px;
  }
`
const ContactInfo = styled.div`
  margin-bottom: 56px !important;
  p {
    text-align: center;
    font-size: 16px !important;
    line-height: 24px !important;
    margin-bottom: 0;
    color: #fff;
  }
`

const DisasterReliefFooter = () => {
  return (
    <Wrapper>
      <Container>
        <Logo logo="abmg-footer-logo-with-icon-white" />
        <ContactInfo>
          <p>600 Lynnhaven Parkway, Suite 100 Virginia Beach VA, 23452</p>
        </ContactInfo>
        <p
          css={`
            margin-bottom: 0;
          `}
        >
          Information is for educational purposes only and should not be relied
          upon by you. Information deemed reliable but not guaranteed. All loans
          subject to income verification, credit approval and property
          appraisal. Not a commitment to lend. Atlantic Bay Mortgage Group,
          L.L.C. NMLS #72043 (
          <a
            href="http://www.nmlsconsumeraccess.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            nmlsconsumeraccess.org
          </a>
          ) is an Equal Opportunity Lender. Located at 600 Lynnhaven Parkway
          Suite 100 Virginia Beach, VA 23452.
        </p>
      </Container>
    </Wrapper>
  )
}

export default DisasterReliefFooter
