export const getWalledLink = (
  mbLink,
  corporateLink,
  walledVersion,
  isMounted,
) => {
  const link =
    walledVersion === "mb"
      ? isMounted
        ? mbLink
        : "javascript:;"
      : corporateLink
  return {
    link,
    type: link.match("//") || link === "" ? "external" : "internal",
  }
}

export const getWalledText = (
  mbText,
  corporateText,
  walledVersion,
  isMounted,
) => {
  return walledVersion === "mb" ? (isMounted ? mbText : "") : corporateText
}
