// Packages
import React, { memo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Logo, getMediaQueries } from "@abmg-components"

// Styles
const { below } = getMediaQueries()
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  .headshot {
    border-radius: 50%;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    transition: all 0.2s ease;
    ${below.tablet`
    width:40px;
    height:40px;
    `}
  }
  &.logo-headshot {
    svg {
      // width: 34px;
      width: auto;
      // width: fit-content;
      height: 40px;
      ${below.tablet`
        // width:29px;
        width: auto;
        // width: fit-content;
        height:34px;
    `}
    }
  }

  &.fullABMGLogo {
    height: 45px;
    margin-right: 40px;
    transition: 0.3s ease-in-out;
    ${below.tablet`
      height:35px;
      margin-right: 0;
    `};
    ${below.mobile`
      height:25px;
    `};
  }
  height: 64px;
  margin-right: 40px;
  transition: 0.3s ease-in-out;
  ${below.tablet`
    max-height:40px;
    margin-right: 0;
  `};
`

const Divider = styled.div`
  flex-shrink: 0;
  margin: 0 16px;
  height: 48px;
  width: 1px;
  background: linear-gradient(#ffffff, #e2e8f0, #ffffff);
  ${below.tablet`
  height:40px;
  margin:0 12px;
  `}
`

const DefaultHeadshot = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 48px;
  height: 48px;
  border: 2px solid hsla(204, 45%, 98%, 0.35);
  display: block;
  background-color: #264765;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  ${below.tablet`
    width:40px;
    height:40px;
    `}
`

// Main Component
function NavHeadshot({ professional, logo }) {
  let image = professional?.headshot?.gatsbyImageData
  return (
    <LogoWrapper className="logo-headshot">
      {logo || <Logo logo="abmg-nav-icon-default" />}
      <Divider />

      {image ? (
        <GatsbyImage
          className="headshot"
          image={image}
          alt={professional.fullName || professional.name || ""}
        />
      ) : (
        <DefaultHeadshot>
          {`${professional?.firstName ? professional.firstName.charAt() : ``}${
            professional?.lastName ? professional.lastName.charAt() : ``
          }`}
        </DefaultHeadshot>
      )}
    </LogoWrapper>
  )
}

// Prop Types
NavHeadshot.propTypes = {
  professional: PropTypes.shape({
    headshot: PropTypes.shape(),
    fullName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  /**
   * Logo component can be used or default logo will be used
   */
  logo: PropTypes.element,
}

const arePropsEqual = (prevProps, nextProps) => {
  // Only update if headshot or logo changes
  return (
    prevProps.professional?.headshot?.gatsbyImageData ===
      nextProps.professional?.headshot?.gatsbyImageData &&
    prevProps.logo === nextProps.logo
  )
}

export default memo(NavHeadshot, arePropsEqual)
