// Packages
import React from "react"
import { Link } from "gatsby"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { colors } from "@abmg-components"

// Components
import ArticleToolTip from "@components/knowledge-center/contentful-components/article-tooltip"

// Categories
export const CategoryBadgeHoverColor = category => {
  if (category === "real estate round-up") {
    return colors["thistle-purple-2"].hex
  }
  if (category === "finances") {
    return colors["palm-green-3"].hex
  }
  if (category === "mortgages") {
    return colors["atlantic-blue-4"].hex
  }
  if (category === "homeownership") {
    return colors["sunset-orange-3"].hex
  }
}

export const CategoryColorLightFinder = category => {
  if (category === "real estate round-up") {
    return colors["thistle-purple-1"].hex
  }
  if (category === "homeownership") {
    return colors["sunset-orange-2"].hex
  }
  if (category === "mortgages") {
    return colors["atlantic-blue-2"].hex
  }

  if (category === "finances") {
    return colors["palm-green-2"].hex
  } else return null
}

export const CategoryColorDarkFinder = category => {
  if (category === "real estate round-up") {
    return colors["thistle-purple-6"].hex
  }
  if (category === "homeownership") {
    return colors["sunset-orange-6"].hex
  }
  if (category === "mortgages") {
    return colors["atlantic-blue-6"].hex
  }

  if (category === "finances") {
    return colors["palm-green-5"].hex
  } else return null
}

export const CategoryTitleString = category => {
  if (category === "real estate round-up") {
    return "REAL ESTATE ROUND-UP"
  }
  if (category === "homeownership") {
    return "HOUSE TO HOME"
  }
  if (category === "mortgages") {
    return "MORTGAGE MATTERS"
  }
  if (category === "finances") {
    return "FINANCIAL WELLNESS"
  } else return null
}

// Rich Text
export const richTextOptions = props => {
  return {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: node => {
        if (node.data.target.__typename === "ContentfulArticleTooltip") {
          return (
            <ArticleToolTip
              category={props.category}
              fields={node.data.target}
              setTooltipOverlay={props.setTooltipOverlay}
            />
          )
        } else if (
          node.data.target.__typename === "ContentfulArticleInternalLink"
        ) {
          let slug
          // If internal link is to a KC article
          if (
            !node.data.target.category ||
            node.data.target.category === "KC"
          ) {
            slug = props.bankerData
              ? `/${props.bankerData?.slug}/knowledge-center/${node.data.target.link}`
              : `/knowledge-center/${node.data.target.link}`
          }
          // If internal link is not to a KC article
          else {
            slug = `/${node.data.target.link}`
          }

          return <Link to={slug}>{node.data.target.linkText}</Link>
        }
      },
      [INLINES.HYPERLINK]: node => {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {node.content[0].value}
          </a>
        )
      },
    },
  }
}
