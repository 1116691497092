// Packages
import React from "react"
import styled, { css } from "styled-components"
import { Address, Licensing } from "@abmg-components"

// Components
import Logo from "@components/global/logo"
import ClientSideOnly from "@components/global/client-side-only"

const size = {
  desktop: 1152,
  tablet: 950,
  mobile: 600,
}
const below = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${size[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})
const Wrapper = styled.div`
  background: #f7fafc;
  min-height: 200px;
  margin: 0;
  padding: 64px 0;
  ${below.tablet``}
  ${below.mobile``}
`
const Container = styled.div`
  max-width: 1248px;
  margin: 0 auto;
  padding: 0 48px;
  ${below.mobile`padding: 0 24px;`}

  p {
    font-size: 14px;
    line-height: 16px;
    color: #2d3748;
  }
  svg {
    display: block;
    margin: 0 auto 40px;
    path {
      fill: hsla(205, 78%, 20%, 1);
    }
  }
`
const ContactInfo = styled.div`
  margin-bottom: 56px !important;
  p {
    text-align: center;
    font-size: 16px !important;
    line-height: 24px !important;
    margin-bottom: 0;
    color: #1a202c !important;
    &.states {
      margin-left: 24px;
    }
    &.NMLS,
    &.states * {
      font-size: 16px !important;
      line-height: 24px !important;
      margin: 0;
      // margin-right: 12px;
      color: hsla(218, 13%, 41%, 1) !important;
    }
  }
  &.team {
    p {
      text-align: left;
    }
    .intro {
      margin-bottom: 34px;
      .title {
        color: var(--arctic-blue-hex);
        font-feature-settings: "pnum" on, "lnum" on;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.03em;
        line-height: 28px;
        text-transform: uppercase;
      }
    }
    .team-members {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      .member {
        flex-basis: 368px;
        .divider {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
`
const LicenseInfo = styled.div`
  display: flex;
  justify-content: center;
`

const WorksFooter = ({ bankerData, version }) => {
  return (
    <Wrapper>
      <Container>
        <Logo color="white" height="48" />
        {version === "mb" && (
          <ClientSideOnly>
            {bankerData?.category === "individual" || !bankerData ? (
              <ContactInfo>
                <p>
                  {bankerData && bankerData.street
                    ? `${bankerData?.street ? bankerData?.street : ""} ${
                        bankerData?.suite ? bankerData?.suite : ""
                      } ${bankerData?.city ? bankerData?.city : ""}, ${
                        bankerData?.state ? bankerData?.state : ""
                      } ${bankerData?.zip ? bankerData?.zip : ""}`
                    : `600 Lynnhaven Parkway, Suite 100 Virginia Beach VA, 23452`}
                </p>
                {bankerData && (
                  <LicenseInfo>
                    <p className="NMLS">{`NMLS #${bankerData?.nmls}`}</p>
                    <p className="states">
                      <Licensing
                        licenses={bankerData.licenses}
                        stateSpecificLicense={bankerData.stateSpecificLicense}
                      />
                      {/*`Licensed in ${bankerData?.licenses}`*/}
                    </p>
                  </LicenseInfo>
                )}
              </ContactInfo>
            ) : (
              bankerData?.category === "team" && (
                <ContactInfo className="team">
                  <div className="intro">
                    <p className="title">{bankerData.name}</p>
                    {bankerData.address && (
                      <p>
                        <Address
                          streetComma={true}
                          cityComma={true}
                          professional={bankerData}
                        />
                      </p>
                    )}
                  </div>
                  {bankerData.team && (
                    <div className="team-members">
                      {bankerData.team.map(member => {
                        return (
                          <div key={member.nmls} className="member">
                            <p>{member.fullName}</p>
                            {!bankerData.address && member.street && (
                              <p>
                                <Address
                                  streetComma={true}
                                  cityComma={true}
                                  professional={member}
                                />
                              </p>
                            )}
                            <p className="licenses">
                              NMLS #{member.nmls}
                              <span className="divider">|</span>
                              <Licensing
                                licenses={member.licenses}
                                stateSpecificLicense={
                                  member.stateSpecificLicense
                                }
                              />
                            </p>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </ContactInfo>
              )
            )}
          </ClientSideOnly>
        )}
        {version === "corporate" && (
          <ContactInfo>
            <p>600 Lynnhaven Parkway, Suite 100 Virginia Beach VA, 23452</p>
          </ContactInfo>
        )}

        <p>
          *AB Insurance LLC is a joint venture between Atlantic Bay Mortgage
          Group and Prosper Insurance. AB Insurance is a full-service insurance
          agency. This is for informational purposes only and AB Insurance does
          not regulate insurance premiums or products. Products and rates may
          vary by state. Safe Haven ADT is not affiliated with Atlantic Bay
          Mortgage Group.
        </p>
        <p>
          <sup>†</sup>Lender credit of .25% of the total loan amount not to
          exceed $2,000 or amount allowed per program guidelines.
        </p>
        <p
          css={`
            margin-bottom: 0;
          `}
        >
          All information deemed reliable but not guaranteed. Limit one
          promotion per transaction. Must be presented at the time of
          application. Atlantic Bay Mortgage Group, L.L.C. is not responsible
          for compensation of all benefits listed. Other loan program
          restrictions may apply. Loan programs, interest rates, and fees are
          subject to change without notice. All loans subject to income
          verification, credit approval and property appraisal. Not a commitment
          to lend. Atlantic Bay Mortgage Group, L.L.C. NMLS #72043
          (nmlsconsumeraccess.org) is an Equal Opportunity Lender. Located at
          600 Lynnhaven Parkway Suite 100 Virginia Beach, VA 23452.
        </p>
      </Container>
    </Wrapper>
  )
}

export default WorksFooter
