import React, { useState } from "react"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import ExternalButton from "./button-external-link"
import ExitButton from "./button-default"

const size = {
  tablet: 950,
  mobile: 600,
}
const below = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${size[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: hsla(0, 0%, 0%, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ModalCard = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 0 48px;
  padding: 40px 48px;
  width: 100%;
  max-width: 864px;
  max-height: 90vh;
  overflow-y: scroll;
  position: relative;
  box-shadow: 0px 4.8412px 29.0472px rgba(19, 101, 186, 0.15);
  ${below.mobile`
    padding: 32px 16px;
    margin: 0 24px;
    `};
  hr {
    margin: 0 0 24px;
    padding: 0;
    border-bottom: 1px solid #e2e8f0;
  }
`
const SelectionSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;

  ${below.tablet`
  display: block;
  max-width: 480px;
  margin: 0 auto 40px;
  `}
  ${below.mobile`
  margin:0 auto 24px;;
  `}
`
const TeamContainer = styled.div`
  width: 50%;
  min-width: 326px;
  margin: 0 0 16px;
  &:last-of-type {
    margin: 0;
  }
  ${below.tablet`
  width: 100%;
  min-width: initial;
  `}
  &:nth-of-type(odd) {
    padding-right: 16px;
    ${below.tablet`padding: 0;`}
  }
`
const TeamCard = styled.button`
  outline: none;
  cursor: pointer;
  text-align: left;
  padding: 24px 16px;
  background-color: white;
  border: 1px solid #cbd2dd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: 0.3s ease-in-out;
  ${below.mobile`
  padding:12px 16px;
  `}

  :hover {
    border: 1px solid #1e8afa;
  }

  &.selected {
    background-color: rgba(30, 138, 250, 0.05);
    border: 1px solid #1e8afa;
  }
`
const Title = styled.h2`
  margin-bottom: 8px;
  ${below.mobile`
  font-size:28px;
  line-height:36px;
  `}
`
const Description = styled.p`
  line-height: 28px;
  color: var(--grey);
  margin-bottom: 40px;
  ${below.mobile`
  font-size:16px;
  line-height:20px;
  margin-bottom:24px;
  `}
`
const LeftSideContainer = styled.div`
  display: flex;
  padding-right: 24px;
`
const Headshot = styled(GatsbyImage)`
  border-radius: 50%;
  margin-right: 20px;
  display: inline-block;
  width: 48px;
  height: 48px;
  ${below.mobile`
  margin-right: 8px;
  `}
`
const Headshotless = styled.div`
  margin-right: 20px;
  border-radius: 50%;
  background-color: #264765;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 900;
  font-size: 12px;
  line-height: 48px;
  width: 48px;
  height: 48px;
`
const Name = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
`
const Licensing = styled.div`
  font-family: "Playfair Display", serif;
  font-size: 12px;
  line-height: 16px;
  color: var(--grey);
  span {
    font-family: "Playfair Display", serif;
    font-size: 12px;
    line-height: 16px;
    color: var(--grey);
  }
`
const Radio = styled.div`
  position: relative;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border: 1px solid #cbd2dd;
  transition: 0.3s ease-in-out;
  :after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #fff;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  &.selected {
    background-color: #1e8afa;
    border: 1px solid #1e8afa;
  }
`
const ActionContainer = styled.div`
  display: flex;
  flex-direction: flex-row-reverse;
  align-items: center;
  /* margin-bottom: 32px; */
  .apply-with {
    width: unset;
    margin-right: 24px;
    ${below.mobile`
    margin-right:0;
    margin:0 auto 16px;
    `}
    &.enabled {
      opacity: 1;
      pointer-events: initial;
    }
    :visited {
      text-decoration: none;
      color: white;
    }
    :hover {
      text-decoration: none;
    }
  }

  .secondary {
    width: unset;
    padding: 12px;
    ${below.mobile`
    margin:0 auto;
    `}
  }
  ${below.mobile`
  display: block;
  
  `}
`

const TeamApplyModal = props => {
  const [selected, setSelected] = useState(false)
  return (
    <ModalWrapper
      onClick={event => {
        event.stopPropagation()
        props.toggleModal(false)
      }}
    >
      <ModalCard onClick={event => event.stopPropagation()}>
        <Title>Ready to apply?</Title>
        <Description>
          Select the Mortgage Banker that you would like to apply with.
        </Description>

        <SelectionSection>
          {props.team
            .filter(teammate => teammate.applyLink)
            .map(teammate => (
              <TeamContainer key={teammate.slug}>
                <TeamCard
                  onClick={() =>
                    selected && selected.fullName === teammate.fullName
                      ? setSelected(false)
                      : setSelected(teammate)
                  }
                  className={
                    selected && selected.fullName === teammate.fullName
                      ? "selected"
                      : undefined
                  }
                >
                  <LeftSideContainer>
                    {teammate.headshot &&
                    teammate.headshot.title !== "profile-default" ? (
                      <Headshot
                        image={teammate.headshot.gatsbyImageData}
                        alt={teammate.fullName}
                        // placeholderStyle={{ filter: "blur(6px)" }}
                      />
                    ) : (
                      <Headshotless>{`${teammate.firstName.charAt()}${teammate.lastName.charAt()}`}</Headshotless>
                    )}

                    <div>
                      <Name>{teammate.fullName}</Name>
                      <Licensing>{`NMLS #${teammate.nmls}`}</Licensing>

                      <Licensing>
                        {`Licensed in `}
                        {teammate.licenses.map(licensedState => (
                          <span key={licensedState}>
                            {`${licensedState}${
                              teammate.licenses.length === 2 &&
                              teammate.licenses.indexOf(licensedState) <
                                teammate.licenses.length - 1
                                ? " & "
                                : ""
                            }${
                              teammate.licenses.length > 2 &&
                              teammate.licenses.indexOf(licensedState) <
                                teammate.licenses.length - 1
                                ? ", "
                                : ""
                            }`}
                          </span>
                        ))}
                      </Licensing>
                    </div>
                  </LeftSideContainer>

                  <Radio
                    className={
                      selected && selected.fullName === teammate.fullName
                        ? "selected"
                        : undefined
                    }
                  />
                </TeamCard>
              </TeamContainer>
            ))}
        </SelectionSection>

        <hr />
        <ActionContainer>
          <ExternalButton
            className={selected ? "enabled apply-with" : "disabled apply-with"}
            linkTo={selected.applyLink}
            buttonText={
              !selected ? `Make selection` : `Apply with ${selected.firstName}`
            }
          />

          <ExitButton
            className="secondary"
            onClick={() => {
              props.toggleModal(false)
            }}
            buttonText="Never mind, for now."
          />
        </ActionContainer>
      </ModalCard>
    </ModalWrapper>
  )
}

export default TeamApplyModal
