// Packages
import React from "react"
import {
  GlobalStyles,
  // eslint-disable-next-line no-unused-vars
  CSSVariables,
} from "@abmg-components"

// Components
import Layout from "@components/global/layout"
import LayoutWalledMB from "@components/global/layout-walled-mb"

// Browser APIs
// export const wrapRootElement = ({ element }) => {
//   return <GlobalContextProvider>{element}</GlobalContextProvider>
// }

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <GlobalStyles />
      {props.pageContext.type !== "noLayout" ? (
        props.pageContext.version === "mb" ||
        props.pageContext.type === "mb" ||
        props.pageContext.pageName?.match("404") ? (
          <>
            <LayoutWalledMB {...props}>{element}</LayoutWalledMB>
          </>
        ) : (
          <Layout {...props}>{element}</Layout>
        )
      ) : (
        element
      )}
    </>
  )
}
