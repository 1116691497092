// Packages
import React from "react"
import styled from "styled-components"

// Components
import Disclosures from "@components/global/disclosures"
import Icon from "@components/asa/icon"

// Utils
import { mediaSizes } from "@utils/mediaSizes"

const { tablet1, desktop1 } = mediaSizes.width

// Styles
const MainContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1152px;
`
const FooterContainer = styled.footer`
  align-items: center;
  background: var(--abmg-grey-ice);
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 64px 24px;
  width: 100%;
  // Tablet 1
  @media (min-width: ${tablet1}px) {
    padding: 64px 48px;
  }
  // Tablet
  @media (min-width: ${desktop1}px) {
    padding: 80px 48px;
  }
`

const NMLSInfo = styled.div`
  margin-bottom: 56px;
  text-align: center;
  p {
    font-size: 16px;
    line-height: 24px;
  }
  .address {
    color: var(--abmg-grey-orca);
    margin-bottom: 0;
  }
  .footer-logo {
    margin-bottom: 40px;
  }
  .nmls,
  .licensed {
    color: var(--abmg-grey-dusky);
    display: block;
    margin-bottom: 0;
  }
  // Tablet 1
  @media (min-width: ${tablet1}px) {
    .nmls,
    .licensed {
      display: inline-block;
      margin-right: 24px;
    }
  }
`

const DisclosuresInfo = styled.div`
  p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 52px;
  }
  .equal-opportunity-lender {
    flex-shrink: 0;
  }
  .info {
    align-items: center;
    color: var(--abmg-grey-pilot);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  // Tablet 1
  @media (min-width: ${tablet1}px) {
    .info {
      align-items: center;
      flex-direction: row;
      p {
        margin-bottom: 0;
        margin-right: 25px;
      }
    }
  }
`

// Main Component
function ASAFooter(props) {
  return (
    <FooterContainer>
      <MainContainer>
        <NMLSInfo className="nmls-info">
          <Icon className="footer-logo" name="footer-logo" />
        </NMLSInfo>
        <DisclosuresInfo className="disclosures">
          <div className="info">
            <Disclosures disclosure={props.disclosure} />
            <Icon
              className="equal-opportunity-lender"
              name="equal-opportunity-lender"
            />
          </div>
        </DisclosuresInfo>
      </MainContainer>
    </FooterContainer>
  )
}

export default ASAFooter
