import React from "react"
import styled from "styled-components"

// By default this button and its "cousins" (button-external-link, button-external-link) have the width set to 100% which will span the full width of the screen which is typical behavior for mobile layouts.
// To make the button width adjust to the size of the content, set the button width to unset

const Button = styled.button`
  display: block;
  background: var(--pacific-blue);
  padding: 12px 40px;
  border-radius: 2px;
  border: 1px solid var(--pacific-blue);
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s ease-in-out;

  /* Primary button styles */

  span {
    color: white;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }

  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
      hue-rotate(149deg) brightness(106%) contrast(102%);
  }

  &:hover,
  &:active {
    background: var(--arctic-blue);
    border: 1px solid var(--arctic-blue);
  }

  /* Secondary button styles */

  &.secondary {
    background: transparent;
    color: var(--pacific-blue);
    border: none;
    span {
      color: var(--pacific-blue);
    }
    img {
      filter: brightness(0) saturate(100%) invert(28%) sepia(82%)
        saturate(4033%) hue-rotate(199deg) brightness(100%) contrast(98%);
    }

    &:hover,
    &:active {
      background: var(--btn-light-blue);
      span {
        color: var(--arctic-blue);
      }
      img {
        filter: brightness(0) saturate(100%) invert(18%) sepia(91%)
          saturate(556%) hue-rotate(166deg) brightness(91%) contrast(100%);
      }
    }
  }
  /* Tertiary Button Styles */
  &.tertiary {
    background: transparent;
    border: 1px solid var(--pacific-blue);
    span {
      color: var(--pacific-blue);
    }

    &:hover,
    &:active {
      background: var(--arctic-blue);
      border: 1px solid var(--arctic-blue);

      span {
        color: #b1d8fe;
      }
      img {
        filter: brightness(0) saturate(100%) invert(81%) sepia(11%)
          saturate(1058%) hue-rotate(178deg) brightness(101%) contrast(99%);
      }
    }
  }

  /* Quaternary Button Styles */

  &.quaternary {
    background: #d5f6ee;
    border: 1px solid #d5f6ee;
    span {
      color: #055461;
    }
    &:hover,
    &:active {
      background: #ffffff;
      border: 1px solid #055461;

      img {
        filter: brightness(0) saturate(100%) invert(10%) sepia(99%)
          saturate(5571%) hue-rotate(181deg) brightness(80%) contrast(96%);
      }
    }
  }

  &.disabled {
    pointer-events: none;
    background: var(--ice-grey);
    border: none;
    span {
      color: #e2e8f0;
    }
  }
`

const ButtonContentsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  img {
    margin-left: 10px;
  }
`

const ButtonDefault = props => {
  return (
    <Button
      type={props.buttonType ? props.buttonType : "button"}
      onClick={props.onClick}
      className={`${props.type === "secondary" ? "secondary" : undefined} ${
        props.className ? props.className : undefined
      }`}
    >
      <ButtonContentsContainer>
        <span>{props.buttonText}</span>
        {props.image && <img src={props.image} alt="button icon" />}
      </ButtonContentsContainer>
    </Button>
  )
}

export default ButtonDefault
